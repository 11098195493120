import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/africa',
    name: 'africa team',
    component: () => import(/* webpackChunkName: "about" */ '../views/AfricaTeam.vue')
  },
  {
    path: '/asia',
    name: 'asia team',
    component: () => import(/* webpackChunkName: "about" */ '../views/AsiaTeam.vue')
  },
  {
    path: '/europe',
    name: 'europe team',
    component: () => import(/* webpackChunkName: "about" */ '../views/EuropeTeam.vue')
  },
  {
    path: '/latin-america',
    name: 'latin america team',
    component: () => import(/* webpackChunkName: "about" */ '../views/LatinAmericaTeam.vue')
  },
  {
    path: '/middle-east',
    name: 'middle east team',
    component: () => import(/* webpackChunkName: "about" */ '../views/MiddleEastTeam.vue')
  },
  {
    path: '/north-america',
    name: 'north america team',
    component: () => import(/* webpackChunkName: "about" */ '../views/NorthAmericaTeam.vue')
  },
  {
    path: '/uk',
    name: 'uk team',
    component: () => import(/* webpackChunkName: "about" */ '../views/UnitedKingdomTeam.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
