<template>
  <div class="home">
    <img class="world-map" src="@/assets/maps/world-map.svg" />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
}
</script>

<style lang="scss">
  div.home {
    display: flex;
    position: relative;
    align-content: center;
    justify-content: center;
    height: auto;
    img.world-map {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
    }
  }
</style>
