<template>
    <svg id="nav_items" xmlns="http://www.w3.org/2000/svg" width="376.421" height="81.039" viewBox="0 0 376.421 81.039">
        <g id="Region_buttons" :class="{ 'home' : isHome }" data-name="Region buttons" transform="translate(-20 -20)">
            <router-link to="/middle-east" class="link">
                <g id="Middle_East" data-name="Middle East" transform="translate(245.379 65.401)">
                    <g data-name="Rectangle 47" transform="translate(0)" fill="#2d2d2d" stroke="#464646" stroke-width="1">
                        <rect width="114.032" height="35" stroke="none"/>
                        <rect x="0.5" y="0.5" width="113.032" height="34" fill="none"/>
                    </g>
                    <g data-name="Group 125" transform="translate(15 10)">
                        <text id="Middle_East-2" data-name="Middle East" transform="translate(15.049 12)" fill="#fff" font-size="13" font-family="HelveticaNeue, Helvetica Neue"><tspan x="0" y="0">Middle East</tspan></text>
                        <path id="Path_1249" data-name="Path 1249" d="M154.3,224.527a3,3,0,1,1-3,3,3,3,0,0,1,3-3" transform="translate(-151.3 -219.871)" fill="#86db4f"/>
                    </g>
                </g>
            </router-link>
            <router-link to="/latin-america" class="link">
                <g id="Latin_America" data-name="Latin America" transform="translate(110.267 65.401)">
                    <g data-name="Rectangle 47" transform="translate(0)" fill="#2d2d2d" stroke="#464646" stroke-width="1">
                        <rect width="125.112" height="35" stroke="none"/>
                        <rect x="0.5" y="0.5" width="124.112" height="34" fill="none"/>
                    </g>
                    <g data-name="Group 125" transform="translate(15 10)">
                        <text id="Latin_America-2" data-name="Latin America" transform="translate(15.112 12)" fill="#fff" font-size="13" font-family="HelveticaNeue, Helvetica Neue"><tspan x="0" y="0">Latin America</tspan></text>
                        <path id="dot" d="M154.3,224.527a3,3,0,1,1-3,3,3,3,0,0,1,3-3" transform="translate(-151.3 -219.871)" fill="#deb8fd"/>
                    </g>
                </g>
            </router-link>
            <router-link to="/africa" class="link">
                <g id="Africa" transform="translate(20 65.401)">
                    <g data-name="Rectangle 47" transform="translate(0 -0.361)" fill="#2d2d2d" stroke="#464646" stroke-width="1">
                        <rect width="80.267" height="36" stroke="none"/>
                        <rect x="0.5" y="0.5" width="79.267" height="35" fill="none"/>
                    </g>
                    <g data-name="Group 125" transform="translate(15 10)">
                        <text id="Africa-2" data-name="Africa" transform="translate(14.267 12.599)" fill="#fff" font-size="14" font-family="HelveticaNeue, Helvetica Neue"><tspan x="0" y="0">Africa</tspan></text>
                        <path id="dot-2" data-name="dot" d="M154.3,224.527a3,3,0,1,1-3,3,3,3,0,0,1,3-3" transform="translate(-151.3 -219.871)" fill="#eb8c21"/>
                    </g>
                </g>
            </router-link>
            <router-link to="/asia" class="link">
                <g id="Asia" transform="translate(327.086 20)">
                    <g data-name="Rectangle 47" transform="translate(0)" fill="#2d2d2d" stroke="#464646" stroke-width="1">
                        <rect width="69.335" height="35" stroke="none"/>
                        <rect x="0.5" y="0.5" width="68.335" height="34" fill="none"/>
                    </g>
                    <g data-name="Group 125" transform="translate(15 10)">
                        <text id="Asia-2" data-name="Asia" transform="translate(14.407 12)" fill="#fff" font-size="13" font-family="HelveticaNeue, Helvetica Neue"><tspan x="0" y="0">Asia</tspan></text>
                        <path id="dot-3" data-name="dot" d="M154.3,224.527a3,3,0,1,1-3,3,3,3,0,0,1,3-3" transform="translate(-151.3 -219.871)" fill="#4dacf1"/>
                    </g>
                </g>
            </router-link>
            <router-link to="/europe" class="link">
                <g id="Europe" transform="translate(231.313 20)">
                    <g data-name="Rectangle 47" transform="translate(0)" fill="#2d2d2d" stroke="#464646" stroke-width="1">
                        <rect width="85.731" height="35" stroke="none"/>
                        <rect x="0.5" y="0.5" width="84.731" height="34" fill="none"/>
                    </g>
                    <g data-name="Group 125" transform="translate(15 10)">
                        <text id="Europe-2" data-name="Europe" transform="translate(14.773 12)" fill="#fff" font-size="13" font-family="HelveticaNeue, Helvetica Neue"><tspan x="0" y="0">Europe</tspan></text>
                        <path id="dot-4" data-name="dot" d="M154.3,224.527a3,3,0,1,1-3,3,3,3,0,0,1,3-3" transform="translate(-151.3 -219.871)" fill="#db536a"/>
                    </g>
                </g>
            </router-link>
            <router-link to="/uk" class="link">
                <g id="UK" transform="translate(159.135 20)">
                    <g data-name="Rectangle 47" transform="translate(0)" fill="#2d2d2d" stroke="#464646" stroke-width="1">
                        <rect width="62.101" height="35" stroke="none"/>
                        <rect x="0.5" y="0.5" width="61.101" height="34" fill="none"/>
                    </g>
                    <g data-name="Group 125" transform="translate(15 10)">
                        <text id="UK-2" data-name="UK" transform="translate(14.178 12)" fill="#fff" font-size="13" font-family="HelveticaNeue, Helvetica Neue"><tspan x="0" y="0">UK</tspan></text>
                        <path id="dot-5" data-name="dot" d="M154.3,224.527a3,3,0,1,1-3,3,3,3,0,0,1,3-3" transform="translate(-151.3 -219.871)" fill="#ffb600"/>
                    </g>
                </g>
            </router-link>
            <router-link to="/north-america" class="link">
                <g id="North_America" data-name="North America" transform="translate(20 20)">
                    <g data-name="Rectangle 47" transform="translate(0)" fill="#2d2d2d" stroke="#464646" stroke-width="1">
                        <rect width="129.135" height="35" stroke="none"/>
                        <rect x="0.5" y="0.5" width="128.135" height="34" fill="none"/>
                    </g>
                    <g data-name="Group 125" transform="translate(15 10)">
                        <text id="North_America-2" data-name="North America" transform="translate(15.135 12)" fill="#fff" font-size="13" font-family="HelveticaNeue, Helvetica Neue"><tspan x="0" y="0">North America</tspan></text>
                        <path id="dot-6" data-name="dot" d="M154.3,224.527a3,3,0,1,1-3,3,3,3,0,0,1,3-3" transform="translate(-151.3 -219.871)" fill="#fff"/>
                    </g>
                </g>
            </router-link>
            
        </g>
    </svg>
</template>

<script>
    export default {
        computed: {
            isHome() {
                return this.$route.name === 'home'
            }
        }
    }
</script>

<style lang="scss">
 svg#nav_items {
    display: block;
    position: relative;
    margin: 0 auto 35px auto;
    width: 100%;
    max-width: 376px;
    a {
        g {
            path {
                fill: #707070;
                transition: fill .4s ease;
            }
            text {
                opacity: .5;
                transition: opacity .4s ease;
            }
        }
        &.router-link-active {
            g {
                text {
                    opacity: 1;
                }
            }
            g#UK {
                path {
                    fill: rgb(255, 182, 0);
                }
            }
            g#North_America {
                path {
                    fill: rgb(255, 255, 255);
                }
            }
            g#Europe {
                path {
                    fill: rgb(219, 83, 106);
                }
            }
            g#Asia {
                path {
                    fill: rgb(77, 172, 241);
                }
            }
            g#Africa {
                path {
                    fill: rgb(235, 140, 33);
                }
            }
            g#Latin_America {
                path {
                    fill: rgb(222, 184, 253);
                }
            }
            g#Middle_East {
                path {
                    fill: rgb(134, 219, 79);
                }
            }
        }
    }
    .home {
        g {
            text {
                opacity: 1;
            }
        }
        g#UK {
            path {
                fill: rgb(255, 182, 0);
            }
        }
        g#North_America {
            path {
                fill: rgb(255, 255, 255);
            }
        }
        g#Europe {
            path {
                fill: rgb(219, 83, 106);
            }
        }
        g#Asia {
            path {
                fill: rgb(77, 172, 241);
            }
        }
        g#Africa {
            path {
                fill: rgb(235, 140, 33);
            }
        }
        g#Latin_America {
            path {
                fill: rgb(222, 184, 253);
            }
        }
        g#Middle_East {
            path {
                fill: rgb(134, 219, 79);
            }
        }
    }
 }
</style>
