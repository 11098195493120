<template>
  <NavItems />
  <router-view/>
</template>

<script>
  // @ is an alias to /src
  import NavItems from '@/components/NavItems'
  export default {
    components: {
    NavItems
}
  }
</script>

<style lang="scss">
  #app {
    display: block;
    position: relative;
    padding-top: 20px;
    width: 100%;
    min-height: calc(100vh - 20px);
    height: 100%;
    background-color: #3C3C3B;
    overflow-x: hidden;
    * {
      font-family: 'Helvetica Neue', sans-serif;
      color: #FFFFFF;
    }
    div.location-list {
        display: grid;
        position: relative;
        grid-template-columns: 1fr 1fr;
        gap: 16px 19px;
        padding: 0 21px;
        &.selection {
          p {
            opacity: .3;
            &.selected {
              opacity: 1;
            }
          }
        }
        p {
            position: relative;
            display: inline-block;
            font-size: 13px;
            margin: 0;
            cursor: pointer;
            transition: opacity .3s ease;
        }
    }
    div.team-carousels {
      position: absolute;
      top: 135px;
      z-index: 1;
      width: 100%;
      height: auto;
      pointer-events: none;
      div.carousel {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #FFFFFF;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s ease;
        &.active {
          opacity: 1;
          pointer-events: all;
        }
        div.banner {
          position: relative;
          display: grid;
          grid-template-columns: 50px 1fr 50px;
          border-bottom: 1px solid #000000;
          img.location-pin {
            display: block;
            position: relative;
            width: 23px;
            height: 30px;
            object-fit: contain;
            object-position: center center;
            align-self: center;
            justify-self: center;
            padding: 10px 13px;
            border-right: 1px solid #000000;
          }
          h2 {
            margin: 0;
            color: #000000;
            font-size: 15px;
            font-weight: 600;
            align-self: center;
            justify-self: left;
            margin-left: 20px;
            text-transform: uppercase;
          }
          img.close-button {
            display: block;
            position: relative;
            width: 19px;
            height: 19px;
            object-fit: contain;
            object-position: center center;
            align-self: center;
            justify-self: center;
            padding: 15px;
            border-left: 1px solid #000000;
            cursor: pointer;
            background: #FFFFFF;
          }
        }
        div.people-carousel {
          display: block;
          position: relative;
          .glide__slides {
            position: relative;
            width: 100%;
            list-style: none;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            touch-action: pan-Y;
            overflow: hidden;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            display: flex;
            flex-wrap: nowrap;
            will-change: transform;
          }
          div.controls {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            button {
              display: block;
              position: relative;
              padding: 0;
              border: none;
              background-color: transparent;
              margin: 0;
              line-height: 0;
              cursor: pointer;
              img.carousel-button-image {
                display: block;
                position: relative;
                width: 50px;
                height: 50px;
              }
            }
          }
          .person {
            display: block;
            position: relative;
            padding: 42px 50px;
            img.person-headshot {
              display: inline-block;
              position: relative;
              width: 155px;
              height: 155px;
              object-fit: cover;
              object-position: center center;
              margin-bottom: 24px;
            }
            img.card-graphic {
              display: block;
              position: absolute;
              left: 222px;
              top: 178px;
              width: 43px;
              height: 19px;
              object-fit: contain;
              object-fit: center center;
            }
            h3.name {
              color: #000000;
              font-size: 18px;
              line-height: 21px;
              font-weight: 500;
              margin: 0 0 2px 0;
            }
            h4.job-title {
              color: #7D7D7D;
              font-size: 15px;
              margin: 2px 0 24px 0;
              line-height: 18px;
            }
            .mobile, .email {
              display: block;
              color: #000000;
              margin: 4px;
              text-decoration: none;
              span {
                font-weight: 600;
                color: #DB536A;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
    img.region-map, svg.region-map {
      display: block;
      position: relative;
      margin: 50px auto 20px auto;
      max-width: 100%;
      g#Location_pins {
        &.selection {
          path {
            fill: #909090;
            &.selected {
              fill: rgb(77, 172, 241);
              filter: drop-shadow(0 0 3px #4dacf1);
            }
          }
        }
        path {
          cursor: pointer;
          transition: fill .3s ease;
        }
      }
    }
  }
</style>
